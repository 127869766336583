import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage = "https://ohmi.tech/img/ex_background.jpg";

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#b3f0ff",
    backgroundPosition: "center",
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <img style={{ display: "none" }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        5万円からのシステム開発
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
        ICT、loT…。こんな言葉を聞くけれども、よくわからない。
        <br />
        <br />
        小規模な事業所のお客様へ、
        <br />
        手頃な価格で、業務効率化のお手伝いをします。
      </Typography>
      <Button variant="contained" size="large" className={classes.button} component="a" href="https://forms.gle/4ZY2FEcHXyRr65dQ9">
        見積もり依頼
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        お見積もり無料。ご相談だけでもお気軽に！
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);

import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(3, 5),
    marginTop: theme.spacing(4),
  },
  link: {
    padding: theme.spacing(1, 1),
  },
  mail: {
    width: 300,
    marginTop: theme.spacing(3),
  },
});

function ProductList(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        日々の業務で、こんなお悩みはありませんか？
      </Typography>
      <br />
      <Typography variant="h5">
        <i class="far fa-check-square"></i> 機械的な作業を人の手で行っている
        <br />
        <br />
        <i class="far fa-check-square"></i> 人手で大量のデータをチェックしている
        <br />
        <br />
        <i class="far fa-check-square"></i> 同じデータを、手作業とコンピュータとで二回処理している
        <br />
        <br />
        <i class="far fa-check-square"></i> 既存のシステムはあるが、古くて動作が遅い
        <br />
        <br />
        <i class="far fa-check-square"></i> 内製のExcelマクロを使っているが、作者不在で改良できず困っている
        <br />
        <br />
        <i class="far fa-check-square"></i> 社内にパソコンに詳しい担当者がおらず、相談先がない
        <br />
      </Typography>
      <Button className={classes.button} component="a" href="https://forms.gle/4ZY2FEcHXyRr65dQ9">
        <Typography variant="h4" component="span">
          上記に該当する方は、ぜひご相談ください
        </Typography>
      </Button>
      <p>豊富な経験と開発実績で、お客様に最適な解決策を良心価格でご提案いたします</p>
      <img src="http://ohmi.tech/img/contact.png" className={classes.mail} alt="メール" />
    </Container>
  );
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductList);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.withStyles,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
});

function ProductSecond(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img src="http://ohmi.tech/img/s_bg.png" className={classes.curvyLines} alt="セクション背景" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src="http://ohmi.tech/img/system1.png" alt="excel" />
              <Typography variant="h6" className={classes.title}>
                EXCELで業務効率化！
              </Typography>
              <Typography variant="h5">
                {"すでにお使いのEXCELやACCESSに、関数やマクロを設定します。"}
                {"いつものソフトを使って、いつもの業務を、素早く完了させるお手伝いをします。"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src="http://ohmi.tech/img/system2.png" alt="スプレッドシート" />
              <Typography variant="h6" className={classes.title}>
                Googleドライブで業務効率化！
              </Typography>
              <Typography variant="h5">
                {"誰かとファイルを共有する必要があるなら、Googleドライブ(無料)をおすすめします。"}
                {"EXCELやWORDとほとんど同じ機能が利用可能。関数やマクロの設定はお任せください。"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src="http://ohmi.tech/img/system3.png" alt="専用システム" />
              <Typography variant="h6" className={classes.title}>
                専用システムで業務効率化！
              </Typography>
              <Typography variant="h5">
                {"表計算ソフトだけでは対応できないような業務の効率化をお手伝い。"}
                {"小規模な事業所で使う、専用のシステムを構築いたします。"}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductSecond.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSecond);

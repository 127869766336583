import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  image: {
    height: 70,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductContact(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img src="/static/themes/onepirate/productCurvyLines.png" className={classes.curvyLines} alt="curvy lines" />
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          こんなお悩みの方はご相談ください
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img src="https://ohmi.tech/img/owner1.svg" alt="困っているオーナー1" className={classes.image} />
                <Typography variant="h5" align="center">
                  ICT化というけれども何をしたらいいか、何をすべきかわからない。
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img src="https://ohmi.tech/img/owner2.svg" alt="困っているオーナー2" className={classes.image} />
                <Typography variant="h5" align="center">
                  業務の効率化をしたいけれども、システム導入のためにそんなにたくさんのお金をかけられない。
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img src="https://ohmi.tech/img/owner3.svg" alt="困っているオーナー3" className={classes.image} />
                <Typography variant="h5" align="center">
                  パソコンが得意な従業員も知り合いもいないので、何をすれば良いかわからない。
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Button color="secondary" size="large" variant="contained" className={classes.button} component="a" href="https://forms.gle/4ZY2FEcHXyRr65dQ9">
          相談してみる
        </Button>
      </Container>
    </section>
  );
}

ProductContact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductContact);

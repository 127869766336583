import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Grid from "@material-ui/core/Grid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
});

function ProductWorks(props) {
  const { classes } = props;

  const images = [
    {
      url: "https://ohmi.tech/img/onlineshop.jpg",
      title: "ヤフオク出品用データ作成",
      width: "40%",
    },
    {
      url: "https://ohmi.tech/img/satei.png",
      title: "査定システム",
      width: "20%",
    },
    {
      url: "https://ohmi.tech/img/Chat_bot.png",
      title: "出退勤時間の記録",
      width: "40%",
    },
    {
      url: "https://ohmi.tech/img/time.jpg",
      title: "勤務時間集計",
      width: "30%",
    },
    {
      url: "https://ohmi.tech/img/ex.png",
      title: "データスクレイピング",
      width: "40%",
    },
    {
      url: "https://ohmi.tech/img/crm.jpg",
      title: "顧客管理システム",
      width: "30%",
    },
    {
      url: "https://ohmi.tech/img/Calendar.png",
      title: "送迎時間集計システム",
      width: "33%",
    },
    {
      url: "https://ohmi.tech/img/img4.jpg",
      title: "領収書発行システム",
      width: "34%",
    },
    {
      url: "https://ohmi.tech/img/img2.jpg",
      title: "既存マクロの修正",
      width: "33%",
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        業務効率化実績
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography component="h3" variant="h6" color="inherit" className={classes.imageTitle}>
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
      <br />
      <br />
      <div>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.title}>
              業務効率化 実績詳細
            </Typography>
            <p>詳細を知りたい見出しをクリック(タップ)してください。</p>
            <Tabs>
              <TabList>
                <Tab>
                  <i class="fab fa-google-drive"></i> 査定金額表示
                </Tab>
                <Tab>
                  <i class="fab fa-google-drive"></i> 送迎時間集計
                </Tab>
                <Tab>
                  <i class="fas fa-desktop"></i> 出退勤時間記録
                </Tab>
                <Tab>
                  <i class="far fa-file-excel"></i> 勤務時間集計
                </Tab>
                <Tab>
                  <i class="fas fa-desktop"></i> 領収書発行
                </Tab>
              </TabList>
              <TabPanel>
                <p>
                  商品査定システム - Googleスプレッドシートを使用
                  <br />
                  <br />
                  ＜課題＞ 中古品買取業者のお客様で、商品査定のためのシステムがなく、EXCELに全てを手入力していたため、時間がかかっていました。他スタッフとの情報共有もできていませんでした。
                  <br />
                  <br />
                  ＜解決策＞ EXCELからスプレッドシートにアプリを変更しました、スプレッドシートに関数を設定し、商品の状態、ブランド名などを入力すると、買取金額が表示される仕組みを作りました。買取金額をすぐに表示できるようになったことと、クラウド化したことで、他のメンバーとも内容の連携がスムーズにできるようになったことで業務の効率化ができました。
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  送迎時間集計システム - Googleスプレッドシートを使用
                  <br />
                  <br />
                  ＜課題＞ 送迎時間の集計のために、紙で印刷をしたものを配布し、それを集めて手入力で集計している。集計作業のための工数がとても多く、ミスなども頻発していました。
                  <br />
                  <br />
                  ＜解決策＞ 紙ベースで集計をしていた送迎時間の希望を、デジタル化しました。GoogleフォームとGoogleスプレッドシートを使用し、希望日時を収集、その後スプレッドシート側で集計を行い、日ごとに出力するようにしました。ペーパーレス化と、ミスの削減、集計時間の削減につながりました。
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  出退勤時間記録システム - LINE公式アカウントとGoogleスプレッドシートを使用
                  <br />
                  <br />
                  ＜課題＞ 出勤簿はいまだに紙に印刷したものに、各自印鑑を押す形式であるため、集計の際にも時間がかかっていました。
                  <br />
                  <br />
                  ＜解決策＞ 紙ベースで集計をしていた出勤簿をLINEを使ってデジタル化しました。出勤簿に印鑑を押す代わりに、LINEの画面上で[出勤][退勤]ボタンを押すだけ。記録した出勤時間などは、Googleスプレッドシートに集約されるようになりました。ペーパーレス化と、業務効率化になりました。
                </p>
                <p>
                  サービスサイト: <a href="https://recorbot.work">Recorbot</a>
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  勤務時間集計システム - EXCELを使用(VBA)
                  <br />
                  <br />
                  ＜課題＞ 従業員がそれぞれ、自分のEXCELシートに出退勤時間を記録していました。それを経理担当者が手入力で給与システムに入力をするため、手間が多くかかっていました。
                  <br />
                  <br />
                  ＜解決策＞ 従業員各自がEXCELに出退勤を記録していた出勤簿から、ボタン一つクリックするだけで自動で集計できるシステムを作成しました。給与システムに入力しやすい形で、集計できるようにしています。集計のための時間が格段に減りました。
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  領収書発行システム - PHP/MySQLを使用
                  <br />
                  <br />
                  ＜課題＞ 領収書発行の依頼があった場合には、都度、売り上げを確認した後、手書きで作成していました。
                  <br />
                  <br />
                  ＜解決策＞ 顧客情報をデータベース化し、そこから必要な情報を抽出してPDFの領収書として発行できるシステムを作成しました。手書きの領収書を作成する手間の削減と、メール添付もできるようになり、ペーパーレス化と業務の効率化ができました。
                </p>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

ProductWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductWorks);

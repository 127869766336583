import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://Ohmi.tech">
        https://Ohmi.tech
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#72c9f3",
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#72c9f3",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#72c9f3",
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid container direction="column" justify="flex-end" className={classes.iconsWrapper} spacing={2}>
              <Grid item className={classes.icons}>
                <a href="https://www.instagram.com/kalon_web/" className={classes.icon}>
                  <img src="https://ohmi.tech/img/insta1.png" alt="instagram" width="100%" height="100%" />
                </a>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              関連リンク
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link href="https://web.kalon.work">Design Kalon</Link>
              </li>
              <li className={classes.listItem}>
                <Link href="https://recorbot.work">Recorbot</Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Button from "../components/Button";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: "flex",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#72c9f3",
    padding: theme.spacing(8, 3),
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    maxWidth: 400,
  },
  button: {
    width: "100%",
    border: "1px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(3, 5),
    marginTop: theme.spacing(6),
    backgroundColor: "#fff",
  },
  imagesWrapper: {
    position: "relative",
  },
  imageDots: {
    position: "absolute",
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: "100%",
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  },
});

function ProductCTA(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form>
              <Typography variant="h2" component="h2" gutterBottom>
                お見積もり無料
              </Typography>
              <Typography variant="h5">小規模な事業所のシステム開発を行うohmi.techです。</Typography>
              <Button className={classes.button} component="a" href="https://forms.gle/4ZY2FEcHXyRr65dQ9">
                ご相談・お問い合わせ
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img src="http://ohmi.tech/img/sh.png" alt="お気軽にご連絡ください" className={classes.image} />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);

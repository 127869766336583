import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React from "react";
import ProductWorks from "./modules/views/ProductWorks";
import ProductList from "./modules/views/ProductList";
import AppFooter from "./modules/views/AppFooter";
import ProductTop from "./modules/views/ProductTop";
import ProductSecond from "./modules/views/ProductSecond";
import ProductContact from "./modules/views/ProductContact";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductTop />
      <ProductSecond />
      <ProductWorks />
      <ProductContact />
      <ProductList />
      <ProductCTA />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
